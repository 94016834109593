
export default {
  layout: 'user',

  props: {
    error: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    errorCode() {
      let status = 500
      if ([403, 404].includes(this.error.statusCode)) {
        status = this.error.statusCode
      }

      return `${status}`
    }
  }
}
