
export default {
  props: {
    /**
     * Icon key
     */
    icon: {
      type: String,
      default: ''
    }
  }
}
