/*
 * Copyright 2020 Digi Dinos JSC. All rights reserved.
 * Email: tech@digidinos.com.
 */

import Vue from 'vue'

import SvgIcon from '~/components/molecules/SvgIcon'

Vue.component('svg-icon', SvgIcon)
