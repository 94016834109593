
import { Menu } from 'ant-design-vue'
import MenuItem from '~/components/molecules/MenuItem'

export default {
  name: 'SubMenu',

  components: { MenuItem },

  data() {
    return {
      isSubMenu: true
    }
  },

  props: {
    ...Menu.SubMenu.props,

    menu: {
      type: Object,
      default: () => {}
    }
  }
}
